<template>
  <v-container
    fluid
    class="fill-height not-found-container">
    <NotFoundOwl id="owl-404"/>
    <br>
    <h1 class="not-found-title">
      {{ $t('notFound.title') }}
    </h1>
    <div class="link-404">
      <v-icon
        small
        color="secondary"
        class="small-cloud">
        cloud
      </v-icon>
      <v-icon
        color="secondary"
        class="cloud">
        cloud
      </v-icon>
      <CyButton
        variant="secondary"
        theme="accent"
        class="back-btn"
        :to="redirectRoute">
        {{ $t('notFound.action') }}
      </CyButton>
      <v-icon
        color="secondary"
        class="cloud second-cloud">
        cloud
      </v-icon>
      <v-icon
        small
        color="secondary"
        class="small-cloud second-cloud">
        cloud
      </v-icon>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import NotFoundOwl from '@/assets/images/owls/404notFound.svg'

export default {
  name: 'CyPageNotFound',
  components: {
    NotFoundOwl,
  },
  props: {
    backRouteTo: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters([
      'isOrgSelected',
    ]),
    backRouteName () {
      const { orgCanonical, backRouteTo: name } = this
      const params = { orgCanonical }
      const backRoute = this.$router.resolve({ name, params })
      const backRouteExists = backRoute.href !== '/'
      return backRouteExists && this.$cycloid.permissions.canDisplayRoute(backRoute)
        ? name
        : null
    },
    redirectRoute () {
      const { orgCanonical, isOrgSelected, backRouteName } = this
      const params = { orgCanonical }
      if (!isOrgSelected) return { name: 'organizations' }
      return backRouteName
        ? { name: backRouteName, params }
        : { name: 'dashboard', params }
    },
  },
  i18n: {
    messages: {
      en: {
        title: '404',
        notFound: {
          action: 'Go back, quietly...',
          title: `That's owl you'll find here`,
        },
      },
      es: {
        title: '404',
        notFound: {
          action: 'Volver, tranquilamente...',
          title: `Eso es todo lo que encontrarás aquí`,
        },
      },
      fr: {
        title: '404',
        notFound: {
          action: 'Revenir sur vos pas, doucement...',
          title: `Vous êtes arrivés à l'autre hibou du site`,
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes foot-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(-5px);
    opacity: 1;
  }

  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}

@keyframes side-look {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }

  50% {
    transform: scale(1, 1);
  }

  90% {
    opacity: 1;
  }

  100% {
    transform: scaleY(0);
    opacity: 0;
  }
}

@keyframes floating-cloud {
  0%,
  100% {
    transform: translate(3px, 2px);
  }

  40% {
    transform: translate(1px, 5px);
  }

  70% {
    transform: translate(4px, 4px);
  }
}

.not-found-title {
  color: get-color("secondary");
}

.link-404 {
  margin-top: 20px;
  text-decoration: none;
}

.back-btn {
  margin: 6px 8px;
}

#owl-404 {
  width: 400px;
  transform-origin: center;

  .medium-teal {
    fill: get-color("secondary");
  }

  .dark-blue {
    fill: get-color("primary");
  }

  .dark-teal {
    fill: get-color("secondary", "dark-1");
  }

  .light-teal {
    fill: #75d3c1;
  }

  .white-teal {
    fill: get-color("secondary", "light-3");
  }

  .white {
    fill: get-color("white");
  }

  .dark-orange {
    fill: #f05825;
  }

  .light-orange {
    fill: get-color("accent", "light-1");
  }

  .brown {
    fill: #86353b;
  }

  .black {
    fill: #231f20;
  }

  #left-iris,
  #right-iris,
  #eyeballs {
    transform-origin: center;
    animation-name: side-look;
    animation-duration: 0.8s;
    animation-delay: 3s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  #left-foot {
    transform-origin: center;
    animation-name: foot-out;
    animation-duration: 1s;
    animation-delay: 2s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  #right-foot {
    transform-origin: center;
    animation-name: foot-out;
    animation-duration: 1s;
    animation-delay: 2.5s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  .small-cloud,
  .cloud {
    animation-name: floating-cloud;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    &.second-cloud {
      animation-delay: 1s;
    }
  }
}

.not-found-container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
